import { Dispatch } from 'redux'
import { userRepository } from '../../../data/repositories/userRepository'
import { UPDATE_SUBSCRIPTION_DATA_ACTION, UpdateSubscriptionDataAction } from './subscriptionActions.redux'
import { StateType } from '../../state/types'
import { stripeRepository } from '../../../data/repositories/stripeRepository'

export type SubscriptionActionsType = {
    getSubscriptionData: () => void
    addUsedCredits: (credits?: number) => void
    getCheckoutSession: (productId: string, store: any) => Promise<string>
    getIfHasEnoughCredits: (store: any) => boolean
}

export const calculateRemainingCredits = (plan: number, usedCredits: number) => {
    switch (plan) {
        case 0:
            return 30 - usedCredits
        case 1:
            return 300 - usedCredits
        default:
            return 1000
    }
}

export const getPlanCredits = (plan: number) => {
    switch (plan) {
        case 0:
            return 30
        case 1:
            return 300
        default:
            return 0
    }
}

// Dev mode
// export const PRODUCT_ID = 'price_1MtZB7LYzRjBFq8ywn5AFvBH'

// Production mode
export const PRODUCT_ID = 'price_1N3OUCLYzRjBFq8yY5Vo0w9a'

export const subscriptionActions = (dispatch: Dispatch): SubscriptionActionsType => {
    const getSubscriptionData: SubscriptionActionsType['getSubscriptionData'] = async () => {
        try {
            let subscriptionData = await userRepository().getQuotas()
            if (!subscriptionData) {
                // Sleep for 5 seconds
                await new Promise((resolve) => setTimeout(resolve, 5000))
                subscriptionData = await userRepository().getQuotas()
            }

            if (subscriptionData) {
                dispatch<UpdateSubscriptionDataAction>({
                    type: UPDATE_SUBSCRIPTION_DATA_ACTION,
                    payload: {
                        subscription: subscriptionData,
                    },
                })
            }
        } catch (e: any) {
            console.error(e)
        }
    }

    const addUsedCredit: SubscriptionActionsType['addUsedCredits'] = async (credits = 1) => {
        try {
            const subscriptionData = await userRepository().addUsedCredit(credits)

            dispatch<UpdateSubscriptionDataAction>({
                type: UPDATE_SUBSCRIPTION_DATA_ACTION,
                payload: {
                    subscription: subscriptionData,
                },
            })
        } catch (e: any) {
            console.error(e)
        }
    }

    const getCheckoutSession: SubscriptionActionsType['getCheckoutSession'] = async (productId, store) => {
        const subscriptionData = await (store.getState() as StateType).subscriptionReducer.subscriptionData

        return await stripeRepository().getCheckoutSession(productId, subscriptionData?.customerId || undefined)
    }

    const getIfHasEnoughCredits: SubscriptionActionsType['getIfHasEnoughCredits'] = (store) => {
        const state = store.getState() as StateType
        const { remainingCredits } = state.subscriptionReducer
        const { chat } = state.chatReducer

        if (chat?.product && remainingCredits) {
            const creditsAfterExecution = remainingCredits - chat.product.creditCost
            return creditsAfterExecution >= 0
        }
        return false
    }

    return {
        getSubscriptionData,
        addUsedCredits: addUsedCredit,
        getCheckoutSession,
        getIfHasEnoughCredits,
    }
}
