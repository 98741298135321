import { supabase } from '../services/supabaseService'
import { Subscription } from '../../domain/models/Subscription'
import { subscriptionParser } from '../parsers/subscriptionParser'

// Add supabase types
export interface UserRepositoryType {
    getQuotas: () => Promise<Subscription | null>
    addUsedCredit: (credits: number) => Promise<Subscription>
}

export const userRepository = (): UserRepositoryType => {
    const getQuotas: UserRepositoryType['getQuotas'] = async (): Promise<Subscription | null> => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const { data, error } = await supabase.from('users_quotas').select('*')

            if (data && data.length > 0) {
                return subscriptionParser.toDomain(data[0])
            }
        }

        return null
    }

    const addUsedCredit: UserRepositoryType['addUsedCredit'] = async (credits): Promise<Subscription> => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const subscription = await getQuotas()
            if (subscription) {
                const usedCredits = subscription.usedCredits + credits
                const { data, error } = await supabase
                    .from('users_quotas')
                    .update({ used_credits: usedCredits })
                    .eq('user_id', user.data.user.id)
                    .select()

                if (data && data.length > 0) {
                    return subscriptionParser.toDomain(data[0])
                }
            }
        }

        throw new Error('Cannot add used credits')
    }

    return {
        getQuotas,
        addUsedCredit,
    }
}
