import { ProductEntity } from '../models/ProductEntity'
import { Product } from '../../domain/models/products/Product'
import { ProductListItem } from '../../domain/models/products/ProductListItem'
import { Message } from '../../domain/models/messages/Message'

export const productParser = {
    toDomain: (entity: ProductEntity): Product => {
        return {
            id: entity.id,
            promptId: entity.prompt_id,
            title: entity.title,
            initialFlow: entity.initial_flow as Message[],
            metadata: entity.metadata as { [key: string]: string },
            postGenerationFlow: entity.post_generation_flow as Message[],
            category: entity.category,
            creditCost: entity.credit_cost,
        }
    },
    toProductListItem: (entity: ProductEntity): ProductListItem => {
        return {
            id: entity.id,
            promptId: entity.prompt_id,
            title: entity.title,
            creditCost: entity.credit_cost,
        }
    },
}
