import { supabase } from '../services/supabaseService'
import { Chat } from '../../domain/models/Chat'
import { HistoryItem } from '../../domain/models/HistoryItem'
import { chatParser } from '../parsers/chatParser'

export interface ChatRepositoryType {
    getChats: () => Promise<HistoryItem[]>
    getChat: (chatId: string) => Promise<Chat>
    saveChat: (chat: Chat) => Promise<number>
}

export const chatRepository = (): ChatRepositoryType => {
    const getChats: ChatRepositoryType['getChats'] = async () => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const { data, error } = await supabase
                .from('chats')
                .select(
                    `
                        id,
                        title,
                        product: product_id(title, category)
                      `,
                )
                .order('id', { ascending: false })

            if (error) {
                throw new Error(error.message)
            }

            if (data) {
                return data as HistoryItem[]
            }

            throw new Error('Chats not found')
        }

        throw new Error('User not found')
    }

    const getChat: ChatRepositoryType['getChat'] = async (chatId) => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const { data, error } = await supabase
                .from('chats')
                .select(
                    `
                        *,
                        product: product_id(id, prompt_id, title, initial_flow, metadata, post_generation_flow, category, credit_cost)
                      `,
                )
                .eq('id', chatId)

            if (error) {
                throw new Error(error.message)
            }

            if (data) {
                return chatParser.toDomain(data[0])
            } else {
                throw new Error('Chat not found')
            }
        }

        throw new Error('User not found')
    }

    // TODO Migrate to Actions and remove try/catch
    const saveChat: ChatRepositoryType['saveChat'] = async (chat: Chat): Promise<number> => {
        try {
            const user = await supabase.auth.getUser()
            if (user?.data?.user) {
                const { data, error } = await supabase
                    .from('chats')
                    .upsert({
                        id: chat.id,
                        title: chat.title || '',
                        user_id: user.data.user.id,
                        messages: chat.messages,
                        product_id: chat.product?.id,
                    })
                    .select('id')

                if (error) {
                    throw new Error(error.message)
                }

                return data[0].id
            }
            throw new Error('User not found')
        } catch (e: any) {
            console.log(e)
            throw new Error(e.message)
        }
    }

    return {
        getChats,
        getChat,
        saveChat,
    }
}
