import React, { FunctionComponent } from 'react'
import { Message, MessageTypes } from '../../domain/models/messages/Message'
import { Box, Flex, Text } from '@chakra-ui/react'
import Colors from '../theme/Colors'

interface Props {
    message: Message
}

const MessageCard: FunctionComponent<Props> = ({ message }) => {
    const replaceUserVariables = (text: string): string => {
        return text.replace('{userName}', 'Joel')
    }

    const renderMessage = () => {
        if (message.type === MessageTypes.DIVIDER) {
            return (
                <Flex className={'dividerWithText'} w={'100%'} alignItems={'center'} textAlign={'center'} mb={4}>
                    <Box maxW={'90%'} px={5} mb={1}>
                        <Text fontSize="sm" as={'b'}>
                            {replaceUserVariables(message.message)}
                        </Text>
                    </Box>
                </Flex>
            )
        } else if (message.isMine) {
            return (
                <Box
                    alignSelf={'flex-end'}
                    backgroundColor={Colors.primary['300']}
                    mb={4}
                    ml={12}
                    display={'inline-block'}
                    borderRadius={12}
                    p={2}
                    px={4}
                    maxW={800}
                    borderColor={Colors.primary['600']}
                    borderWidth={1}
                    whiteSpace={'pre-wrap'}>
                    <Text fontSize="lg">{replaceUserVariables(message.message)}</Text>
                </Box>
            )
        } else {
            return (
                <Box
                    backgroundColor={Colors.whiteOpacity}
                    mb={4}
                    mr={12}
                    display={'inline-block'}
                    borderRadius={12}
                    p={2}
                    px={4}
                    maxW={800}
                    borderColor={Colors.primary['500']}
                    borderWidth={1}
                    whiteSpace={'pre-wrap'}>
                    <Text fontSize="lg">{replaceUserVariables(message.message)}</Text>
                </Box>
            )
        }
    }

    return renderMessage()
}

export default MessageCard
