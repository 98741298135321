import { Chat } from '../../models/Chat'

export const SHOW_NEW_CHAT_DIALOG = 'SHOW_NEW_CHAT_DIALOG'
export const SET_CHAT_ACTION = 'SET_CHAT_ACTION'
export const LOAD_CHAT_ACTION = 'LOAD_CHAT_ACTION'
export const SET_SAVE_CHAT_ON_MESSAGE_ACTION = 'SET_SAVE_CHAT_ON_MESSAGE_ACTION'
export const RESET_CHAT_STATE = 'RESET_CHAT_STATE'
export const SET_POST_GENERATION_FLOW_STEP = 'SET_POST_GENERATION_FLOW_STEP'

export interface ShowNewChatDialogAction {
    type: typeof SHOW_NEW_CHAT_DIALOG
    payload: {
        showNewChatDialog: boolean
    }
}

export interface SetChatAction {
    type: typeof SET_CHAT_ACTION
    payload: {
        chat: Chat | null
    }
}

export interface LoadChatAction {
    type: typeof LOAD_CHAT_ACTION
    payload: {
        chat: Chat | null
    }
}

export interface SetSaveChatOnMessageAction {
    type: typeof SET_SAVE_CHAT_ON_MESSAGE_ACTION
    payload: {
        saveChatOnNewMessage: boolean
    }
}

export interface SetPostGenerationFlowStepAction {
    type: typeof SET_POST_GENERATION_FLOW_STEP
    payload: {
        postGenerationFlowStep: number
    }
}

export interface ResetChatStateAction {
    type: typeof RESET_CHAT_STATE
}

export type ChatActionReduxTypes =
    | ShowNewChatDialogAction
    | SetChatAction
    | LoadChatAction
    | SetSaveChatOnMessageAction
    | ResetChatStateAction
    | SetPostGenerationFlowStepAction
