import { initialState } from '../initialState'
import {
    ChatActionReduxTypes,
    LOAD_CHAT_ACTION,
    RESET_CHAT_STATE,
    SET_CHAT_ACTION,
    SET_POST_GENERATION_FLOW_STEP,
    SET_SAVE_CHAT_ON_MESSAGE_ACTION,
    SHOW_NEW_CHAT_DIALOG,
} from '../../actions/chat/chatActions.redux'
import { Chat } from '../../models/Chat'

export interface ChatReducerType {
    chat: Chat | null
    saveChatOnNewMessage: boolean
    showNewChatDialog: boolean
    postGenerationFlowStep: number
}

export const chatReducer = (
    state: ChatReducerType = initialState.chatReducer,
    action: ChatActionReduxTypes,
): ChatReducerType => {
    switch (action.type) {
        case SET_CHAT_ACTION:
            return {
                ...state,
                chat: action.payload.chat,
            }
        case LOAD_CHAT_ACTION:
            return {
                ...state,
                saveChatOnNewMessage: true,
                chat: action.payload.chat,
            }
        case SHOW_NEW_CHAT_DIALOG:
            return {
                ...state,
                showNewChatDialog: action.payload.showNewChatDialog,
            }
        case SET_SAVE_CHAT_ON_MESSAGE_ACTION:
            return {
                ...state,
                saveChatOnNewMessage: action.payload.saveChatOnNewMessage,
            }
        case SET_POST_GENERATION_FLOW_STEP:
            return {
                ...state,
                postGenerationFlowStep: action.payload.postGenerationFlowStep,
            }
        case RESET_CHAT_STATE:
            return {
                ...initialState.chatReducer,
            }
        default:
            return { ...state }
    }
}
