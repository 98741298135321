import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Flex,
    useToast,
} from '@chakra-ui/react'
import { MainLayout } from '../../layouts/MainLayout'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Message, MessageTypes } from '../../../domain/models/messages/Message'
import { MessageResponseTypes } from '../../../domain/models/responses/MessageResponse'
import { MessageSelect } from '../../../domain/models/messages/MessageSelect'
import { MessageResponseOption } from '../../../domain/models/responses/MessageResponseSelect'
import ChatSection from '../../components/ChatSection'
import ActionSection from '../../components/ActionSection'
import { chatRepository } from '../../../data/repositories/chatRepository'
import { getSupabaseSession } from '../../../data/services/supabaseService'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { completionsRepository } from '../../../data/repositories/completionsRepository'
import { generatePromptInputsFromMessages } from '../../../utils/completionFormater'
import { useDispatch, useSelector } from 'react-redux'
import { subscriptionActions } from '../../../domain/actions/subscription/subscriptionActions'
import { useTranslation } from 'react-i18next'
import { StateType } from '../../../domain/state/types'
import { chatActions } from '../../../domain/actions/chat/chatActions'
import { historyActions } from '../../../domain/actions/history/historyActions'
import i18n from 'i18next'
import { categoriesActions } from '../../../domain/actions/categories/categoryActions'
import { getUserLanguage } from '../../../utils/fucntions'
import CategoriesSection from '../../components/CategoriesSection'
import { productActions } from '../../../domain/actions/product/productActions'
import { Chat } from '../../../domain/models/Chat'
import mixpanel from 'mixpanel-browser'
import { ChatTrackEvents } from '../../../utils/trackEvents'

const ChatScreen = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const toast = useToast()
    const { t } = useTranslation()

    const { chat, saveChatOnNewMessage, showNewChatDialog, postGenerationFlowStep } = useSelector(
        (state: StateType) => {
            return state.chatReducer
        },
    )

    const { categories, error: categoriesError } = useSelector((state: StateType) => {
        return state.categoriesReducer
    })

    const { selectedProduct } = useSelector((state: StateType) => {
        return state.productReducer
    })

    const subscriptionData = useSelector((state: StateType) => {
        return state.subscriptionReducer.subscriptionData
    })

    const [initialFlowStep, setInitialFlowStep] = useState<number>(0)
    const [loadingMessages, setLoadingMessages] = useState<boolean>(false)
    const [showCategorySelection, setShowCategorySelection] = useState<boolean>(false)
    const [chatId, setChatId] = useState<number | undefined>(undefined)

    const lastMessageRef = useRef<any>()
    const cancelRef = React.useRef(null)

    useEffect(() => {
        getSupabaseSession(history).then((session) => {
            if (session?.user.id) {
                mixpanel.identify(session.user.id)
            }
            mixpanel.track(ChatTrackEvents.CHAT_PAGE_VISIT)
            subscriptionActions(dispatch).getSubscriptionData()
            setShowCategorySelection(true)
        })
    }, [])

    useEffect(() => {
        if (chat) {
            if (
                !chat.initialFlowFinished &&
                chat.messages?.length === 0 &&
                selectedProduct &&
                selectedProduct?.initialFlow?.length > 0
            ) {
                addMessages([selectedProduct.initialFlow[0]])
                setInitialFlowStep(0)
                setLoadingMessages(false)
            } else if (chat.messages?.length > 0) {
                setLoadingMessages(false)
                setShowCategorySelection(false)
            }
        }
    }, [chat])

    useEffect(() => {
        if (chat && chat.messages.length > 0) {
            const lastMessage = chat.messages[chat.messages.length - 1]

            if (lastMessage.type === MessageTypes.SUBMIT) {
                setLoadingMessages(true)
                chatActions(dispatch).setPostGenerationFlowStep(-1)
                const promptInputs = generatePromptInputsFromMessages(chat.messages)
                const promptId = chat.product?.promptId ? chat.product?.promptId : selectedProduct?.promptId || ''
                const creditCost = chat.product?.creditCost
                    ? chat.product?.creditCost
                    : selectedProduct?.creditCost || 1

                completionsRepository()
                    .generateMessage(promptInputs, promptId, getUserLanguage(i18n.language))
                    .then((message) => {
                        if (message) {
                            if (chat.initialFlowFinished) {
                                mixpanel.track(ChatTrackEvents.CHAT_POST_FLOW_COMPLETED)
                            } else {
                                mixpanel.track(ChatTrackEvents.CHAT_INIT_FLOW_COMPLETED)
                            }

                            const newMessage: Message = {
                                type: MessageTypes.MESSAGE_HIGHLIGHT,
                                message,
                                response: null,
                                isMine: false,
                            }
                            addMessages(
                                [
                                    {
                                        message: chat.initialFlowFinished
                                            ? chat.product?.metadata['other_generations_phrase'] || ' - '
                                            : chat.product?.metadata['first_generation_phrase'] || ' - ',
                                        response: null,
                                        type: MessageTypes.DIVIDER,
                                    },
                                    newMessage,
                                ],
                                { initialFlowFinished: true },
                            )

                            chatActions(dispatch).setSaveChatOnNewMessage(true)
                            setLoadingMessages(false)
                            subscriptionActions(dispatch).addUsedCredits(creditCost)
                        }
                    })
                    .catch((error) => {
                        mixpanel.track(ChatTrackEvents.ERROR_CHAT_COMPLETION, { error })
                        toast({
                            title: t('error.cannotGenerateAnswer.title'),
                            description: t('error.cannotGenerateAnswer.description'),
                            status: 'error',
                            position: 'bottom',
                            duration: 3000,
                            isClosable: true,
                        })
                    })
            } else if (
                lastMessage.type === MessageTypes.MESSAGE_HIGHLIGHT ||
                (chat.initialFlowFinished && (!lastMessage.response || lastMessage.isMine))
            ) {
                if (postGenerationFlowStep === -1) saveChat()

                const nextPostGenerationFlow = postGenerationFlowStep + 1
                if (nextPostGenerationFlow < (chat.product?.postGenerationFlow?.length || 0)) {
                    setTimeout(() => {
                        const newMessage = chat.product?.postGenerationFlow[nextPostGenerationFlow]
                        if (newMessage) {
                            chatActions(dispatch).setPostGenerationFlowStep(nextPostGenerationFlow)
                            addMessages([newMessage])
                        }
                    }, 1000)
                }
            } else if (!chat.initialFlowFinished && (!lastMessage.response || lastMessage.isMine)) {
                const nextInitialFlowStep = initialFlowStep + 1
                if (nextInitialFlowStep < (chat.product?.initialFlow?.length || 0)) {
                    setTimeout(() => {
                        const newMessage = chat.product?.initialFlow[nextInitialFlowStep]
                        if (newMessage) {
                            setInitialFlowStep(nextInitialFlowStep)
                            addMessages([newMessage])
                        }
                    }, 1000)
                }
            }

            scrollToLastMessage()
        }
    }, [chat?.messages])

    const saveChat = () => {
        if (chat) {
            const chatIdToSave = chat.id ? chat.id : chatId
            const product = chat.product ? chat.product : selectedProduct

            if (product) {
                chatRepository()
                    .saveChat({ ...chat, id: chatIdToSave, product })
                    .then((chatId) => {
                        setChatId(chatId)
                        historyActions(dispatch).getUserHistory()
                    })
                    .catch((error) => {
                        showSaveChatError()
                    })
            } else {
                showSaveChatError()
            }
        }
    }

    useEffect(() => {
        if (chat && chat.messages.length > 0 && loadingMessages) {
            scrollToLastMessage()
        }
    }, [loadingMessages])

    useEffect(() => {
        if (showNewChatDialog && saveChatOnNewMessage) {
            chatActions(dispatch).setShowNewChatDialog(false)
            createNewChat()
        }
    }, [showNewChatDialog])

    useEffect(() => {
        if (showCategorySelection) {
            if (categories.length == 0) {
                categoriesActions(dispatch).getCategories(getUserLanguage(i18n.language))
            }
        }
    }, [showCategorySelection])

    useEffect(() => {
        if (categoriesError) {
            toast({
                title: t(`${categoriesError}.title`),
                description: t(`${categoriesError}.description`),
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [categoriesError])

    const showSaveChatError = () => {
        toast({
            title: t('error.cannotSaveChat.title'),
            description: t('error.cannotSaveChat.description'),
            status: 'error',
            position: 'bottom',
            duration: 3000,
            isClosable: true,
        })
    }

    const getNewChat = () => {
        if (selectedProduct) {
            const newChat: Chat = {
                messages: [],
                initialFlowFinished: false,
                product: selectedProduct,
            }

            mixpanel.track(ChatTrackEvents.NEW_CHAT_LOADED)
            chatActions(dispatch).setChat(newChat)
        }
    }

    const createNewChat = () => {
        setLoadingMessages(true)
        setChatId(undefined)
        setInitialFlowStep(0)
        setShowCategorySelection(true)

        mixpanel.track(ChatTrackEvents.STARTING_NEW_CHAT)
        chatActions(dispatch).resetChatState()
        productActions(dispatch).resetProductState()
    }

    const addMessages = (messages: Message[], extraData?: object) => {
        if (chat) {
            const newChat = { ...chat, ...extraData }
            newChat.messages = [...newChat.messages, ...messages]
            chatActions(dispatch).setChat(newChat)
        }
    }

    const scrollToLastMessage = () => {
        lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleOnProductSelected = () => {
        setShowCategorySelection(false)
        getNewChat()
    }

    const handleSelectResponse = (selectedOption: MessageResponseOption) => {
        if (chat) {
            const lastMessage = (
                chat.messages.length > 0 ? chat.messages[chat.messages.length - 1] : null
            ) as MessageSelect | null
            if (lastMessage == null) {
                return
            }

            const newMessage: MessageSelect = {
                message: selectedOption.label,
                response: {
                    ...lastMessage.response,
                    type: MessageResponseTypes.SELECT,
                    selectedOption: selectedOption.value,
                },
                type: MessageTypes.MESSAGE,
                isMine: true,
            }
            addMessages([newMessage])
        }
    }

    const handleSendMessage = (value: string) => {
        if (chat !== null) {
            const newMessage = {
                message: value,
                response: null,
                type: MessageTypes.MESSAGE,
                isMine: true,
            }

            const lastMessage = chat.messages.length > 0 ? chat.messages[chat.messages.length - 1] : null
            if (lastMessage?.response?.type === MessageResponseTypes.TITLE) {
                addMessages([newMessage], { title: value })
            } else {
                addMessages([newMessage])
            }
        }
    }

    // TODO Add writing animation to load message, and animations to load new messages and input to chat
    return (
        <MainLayout>
            <Flex flex={1} w={'100%'} direction={'column'} justifyContent={'space-between'}>
                {showCategorySelection ? (
                    <CategoriesSection categories={categories} onProductSelected={handleOnProductSelected} />
                ) : (
                    <>
                        <ChatSection
                            messages={chat?.messages || []}
                            isLoadingMessages={loadingMessages}
                            lastMessageRef={lastMessageRef}
                        />
                        <ActionSection
                            messages={chat?.messages || []}
                            onSendMessageClicked={(value) => handleSendMessage(value)}
                            onResponseSelected={handleSelectResponse}
                        />
                        <AlertDialog
                            isOpen={showNewChatDialog && !saveChatOnNewMessage}
                            leastDestructiveRef={cancelRef}
                            onClose={() => chatActions(dispatch).setShowNewChatDialog(false)}>
                            <AlertDialogOverlay>
                                <AlertDialogContent marginX={4}>
                                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                        {t('newChatDialog.title')}
                                    </AlertDialogHeader>
                                    <AlertDialogBody>{t('newChatDialog.description')}</AlertDialogBody>
                                    <AlertDialogFooter>
                                        <Button
                                            ref={cancelRef}
                                            onClick={() => chatActions(dispatch).setShowNewChatDialog(false)}>
                                            {t('common.cancel')}
                                        </Button>
                                        <Button
                                            colorScheme="red"
                                            onClick={() => {
                                                createNewChat()
                                                chatActions(dispatch).setShowNewChatDialog(false)
                                            }}
                                            ml={3}>
                                            {t('newChatDialog.continue')}
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>
                    </>
                )}
            </Flex>
        </MainLayout>
    )
}

export default ChatScreen
