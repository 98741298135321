// ACTION TYPES
import { Category } from '../../models/Category'

export const GET_CATEGORIES_ACTION = 'GET_CATEGORIES_ACTION'
export const SET_CATEGORIES_ERROR_ACTION = 'SET_CATEGORIES_ERROR_ACTION'

// ACTION CREATORS
export interface GetCategoriesAction {
    type: typeof GET_CATEGORIES_ACTION
    payload: {
        categories: Category[]
    }
}

export interface SetCategoriesErrorAction {
    type: typeof SET_CATEGORIES_ERROR_ACTION
    payload: {
        error: string
    }
}

export type CategoriesActionReduxTypes = GetCategoriesAction | SetCategoriesErrorAction
