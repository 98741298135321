export const AuthTrackEvents = {
    AUTH_PAGE_VISIT: 'Auth page visit',
    TOC_LINK_CLICKED: 'Toc link clicked',
    SIGNED_IN: 'Signed in',
}

export const ChatTrackEvents = {
    CHAT_PAGE_VISIT: 'Chat page visit',
    CHAT_INIT_FLOW_COMPLETED: 'Chat init flow completed',
    CHAT_POST_FLOW_COMPLETED: 'Chat post flow completed',
    NEW_CHAT_LOADED: 'New chat loaded',
    STARTING_NEW_CHAT: 'Starting new chat',
    ERROR_CHAT_COMPLETION: 'Error chat completion',
}

export const CategoriesTrackEvents = {
    CATEGORY_SELECTED: 'Category selected',
    CATEGORY_UNSELECTED: 'Category unselected',
    PRODUCT_SELECTED: 'Product selected',
}

export const HistoryTrackEvents = {
    HISTORY_ITEM_CLICKED: 'History item clicked',
}

export const PaymentTrackEvents = {
    SUCCESS_PAGE_VISIT: 'Success page visit',
    HEADER_UPGRADE_BUTTON_CLICKED: 'Header upgrade button clicked',
    NO_CREDITS_MESSAGE_CLICKED: 'No credits message clicked',
    CUSTOMER_PORTAL_CLICKED: 'Customer portal clicked',
}
