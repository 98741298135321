import { supabase } from '../services/supabaseService'
import { ProductListItem } from '../../domain/models/products/ProductListItem'
import { productParser } from '../parsers/productParser'
import { ProductEntity } from '../models/ProductEntity'
import { Product } from '../../domain/models/products/Product'

export interface ProductRepositoryType {
    getProductListByCategory: (categoryId: number, language: string) => Promise<ProductListItem[]>
    getProductById: (promptId: string, language: string) => Promise<Product>
}

export const productRepository = (): ProductRepositoryType => {
    const getProductListByCategory: ProductRepositoryType['getProductListByCategory'] = async (
        categoryId,
        language,
    ): Promise<ProductListItem[]> => {
        const { data: products, error } = await supabase
            .from('products')
            .select('*')
            .eq('category', categoryId)
            .eq('language', language)
            .eq('published', true)
            .eq('archived', false)

        if (error) {
            throw new Error(error.message)
        }

        return products.map((entity) => productParser.toProductListItem(entity as ProductEntity))
    }

    const getProductById: ProductRepositoryType['getProductById'] = async (promptId, language): Promise<Product> => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const { data, error } = await supabase
                .from('products')
                .select('*')
                .eq('prompt_id', promptId)
                .eq('language', language)

            if (error) {
                throw new Error(error.message)
            }

            if (data && data.length > 0) {
                return productParser.toDomain(data[0] as ProductEntity)
            } else {
                throw new Error('Product not found')
            }
        }

        throw new Error('User not found')
    }

    return {
        getProductListByCategory,
        getProductById,
    }
}
