import { axiosInstance } from '../services/axiosService'
import { getSupabaseSession } from '../services/supabaseService'

export interface StripeRepositoryType {
    getCheckoutSession: (productId: string, customerId?: string) => Promise<string>
    getCustomerPortalSession: (customerId: string) => Promise<string>
}

export const stripeRepository = (): StripeRepositoryType => {
    const getCheckoutSession: StripeRepositoryType['getCheckoutSession'] = async (productId, customerId) => {
        try {
            const supabaseSession = await getSupabaseSession()
            if (supabaseSession) {
                const token = supabaseSession.access_token
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }

                const result = await axiosInstance.post(
                    'https://knesrluvmaqpgincoaqk.functions.supabase.co/stripe-checkout',
                    { productId, customerId, userId: supabaseSession.user.id, userEmail: supabaseSession.user.email },
                    { headers },
                )
                return result?.data?.checkoutUrl || null
            }

            throw new Error('No session')
        } catch (e: any) {
            console.log(e)
            throw new Error(e.message)
        }
    }

    const getCustomerPortalSession: StripeRepositoryType['getCustomerPortalSession'] = async (customerId) => {
        try {
            const supabaseSession = await getSupabaseSession()
            if (supabaseSession) {
                const token = supabaseSession.access_token
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }

                const result = await axiosInstance.post(
                    'https://knesrluvmaqpgincoaqk.functions.supabase.co/stripe-customer-portal',
                    { customerId },
                    { headers },
                )

                return result?.data?.customerPortalUrl || null
            }

            throw new Error('No session')
        } catch (e: any) {
            console.log(e)
            throw new Error(e.message)
        }
    }

    return {
        getCheckoutSession,
        getCustomerPortalSession,
    }
}
