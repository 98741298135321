import Colors from './Colors'

export const BrandTheme = {
    colors: {
        brand: {
            300: Colors.primary[300],
            500: Colors.primary[500],
            600: Colors.primary[600],
            900: Colors.primary[900],
        },
        ctas: {
            500: Colors.primary[100],
            600: Colors.primary[300],
        },
    },
}

export default BrandTheme
