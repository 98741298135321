import { StateType } from './types'

export const initialState: StateType = {
    subscriptionReducer: {
        subscriptionData: null,
        creditsAvailable: true,
        remainingCredits: 0,
    },
    historyReducer: {
        userHistory: [],
        error: '',
    },
    chatReducer: {
        chat: null,
        saveChatOnNewMessage: false,
        showNewChatDialog: false,
        postGenerationFlowStep: -1,
    },
    categoriesReducer: {
        categories: [],
        error: '',
    },
    productReducer: {
        productList: [],
        selectedProduct: null,
        error: '',
    },
}
