import { Category } from '../../domain/models/Category'
import { supabase } from '../services/supabaseService'
import { CategoryEntity } from '../models/CategoryEntity'
import { categoryParser } from '../parsers/categoryParser'

export interface CategoryRepositoryType {
    getAllCategories: (language: string) => Promise<Category[]>
}

export const categoryRepository = (): CategoryRepositoryType => {
    const getAllCategories: CategoryRepositoryType['getAllCategories'] = async (
        language: string,
    ): Promise<Category[]> => {
        const { data, error } = await supabase.from('categories').select('*').eq('published', true)

        if (error) {
            throw new Error(error.message)
        }

        return data.map((entity) => categoryParser.toDomain(entity as CategoryEntity, language))
    }

    return {
        getAllCategories,
    }
}
