import { CategoryEntity } from '../models/CategoryEntity'
import { Category } from '../../domain/models/Category'

export const categoryParser = {
    toDomain: (entity: CategoryEntity, language: string): Category => {
        const { id, value, published, icon } = entity
        const { title, description } = entity.translations[language]
        return { id, value, published, title, description, icon }
    },
}
