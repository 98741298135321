import { axiosInstance } from '../services/axiosService'
import { getSupabaseSession, supabase } from '../services/supabaseService'

export interface CompletionsRepositoryType {
    generateMessage: (promptInputs: object, promptId: string, language: string) => Promise<string | null>
}

// TODO Migrate to Actions and remove try/catch
export const completionsRepository = (): CompletionsRepositoryType => {
    const generateMessage: CompletionsRepositoryType['generateMessage'] = async (promptInputs, promptId, language) => {
        try {
            const supabaseSession = await getSupabaseSession()
            if (supabaseSession) {
                const token = supabaseSession.access_token
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }

                const result = await axiosInstance.post(
                    'https://knesrluvmaqpgincoaqk.functions.supabase.co/generate-completion',
                    { promptInputs, promptId, language, customerId: supabaseSession.user.id },
                    { headers },
                )
                return result?.data?.message || null
            }

            throw new Error('No session')
        } catch (e: any) {
            console.log(e)
            throw new Error(e.message)
        }
    }

    return {
        generateMessage,
    }
}
