import { initialState } from '../initialState'
import {
    CLEAN_PRODUCT_ACTION,
    CLEAN_PRODUCTS_LIST_ACTION,
    ProductActionReduxTypes,
    RESET_PRODUCT_STATE_ACTION,
    SET_PRODUCT_ACTION,
    SET_PRODUCT_ERROR_ACTION,
    SET_PRODUCTS_LIST_ACTION,
} from '../../actions/product/productActions.redux'
import { ProductListItem } from '../../models/products/ProductListItem'
import { Product } from '../../models/products/Product'

export interface ProductReducerType {
    productList: ProductListItem[]
    selectedProduct: Product | null
    error: string
}

export const productReducer = (
    state: ProductReducerType = initialState.productReducer,
    action: ProductActionReduxTypes,
): ProductReducerType => {
    switch (action.type) {
        case SET_PRODUCTS_LIST_ACTION:
            return {
                ...state,
                productList: action.payload.productList,
            }
        case SET_PRODUCT_ACTION:
            return {
                ...state,
                selectedProduct: action.payload.product,
            }
        case CLEAN_PRODUCTS_LIST_ACTION:
            return {
                ...state,
                productList: [],
            }
        case CLEAN_PRODUCT_ACTION:
            return {
                ...state,
                selectedProduct: null,
            }
        case SET_PRODUCT_ERROR_ACTION:
            return {
                ...state,
                error: action.payload.error,
            }
        case RESET_PRODUCT_STATE_ACTION:
            return initialState.productReducer
        default:
            return { ...state }
    }
}
