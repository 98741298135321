import React, { FunctionComponent, useEffect, useState } from 'react'
import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Colors from '../../theme/Colors'
import { HistoryItem } from '../../../domain/models/HistoryItem'
import { historyActions } from '../../../domain/actions/history/historyActions'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from '../../../domain/state/types'
import { AiOutlineClose } from 'react-icons/ai'
import { chatActions } from '../../../domain/actions/chat/chatActions'
import { Category } from '../../../domain/models/Category'
import mixpanel from 'mixpanel-browser'
import { HistoryTrackEvents } from '../../../utils/trackEvents'

interface Props {
    showCloseButton?: boolean
    onClose?: () => void
}

const History: FunctionComponent<Props> = ({ showCloseButton = false, onClose }) => {
    const { t } = useTranslation()
    const toast = useToast()
    const dispatch = useDispatch()

    const [categoryMap, setCategoryMap] = useState<{ [key: number]: Category }>({})

    const { userHistory, error: historyError } = useSelector((state: StateType) => {
        return state.historyReducer
    })

    const { categories } = useSelector((state: StateType) => {
        return state.categoriesReducer
    })

    useEffect(() => {
        if (userHistory && userHistory.length > 0) return
        else {
            historyActions(dispatch).getUserHistory()
        }
    }, [])

    useEffect(() => {
        if (historyError) {
            toast({
                title: t('error.cannotGetHistory.title'),
                description: t('error.cannotGetHistory.description'),
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [historyError])

    useEffect(() => {
        const newCategoryMap: { [key: number]: Category } = {}
        categories.forEach((category) => {
            newCategoryMap[category.id] = category
        })
        setCategoryMap(newCategoryMap)
    }, [categories])

    const getCategoryName = (categoryId: number) => {
        if (categoryMap[categoryId]) {
            return categoryMap[categoryId].title
        } else {
            return ''
        }
    }

    const renderHistoryItem = (item: HistoryItem) => {
        return (
            <Box
                key={item.id}
                p={2}
                backgroundColor={Colors.primary['100']}
                borderRadius={6}
                my={1}
                cursor={'pointer'}
                transition={'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform'}
                transitionDuration={'0.2s'}
                _hover={{
                    backgroundColor: Colors.primary['300'],
                }}
                onClick={() => {
                    mixpanel.track(HistoryTrackEvents.HISTORY_ITEM_CLICKED)
                    chatActions(dispatch).loadChatFromHistory(item.id)
                }}>
                <Text fontSize="md" noOfLines={1} color={Colors.text}>
                    {item.title || '-'}
                </Text>
                <Text fontSize="xs" as={'i'} noOfLines={1} color={Colors.textSecondary}>
                    {`${getCategoryName(item.product.category)} - ${item.product.title || ''}`}
                </Text>
            </Box>
        )
    }

    const renderHistory = () => {
        if (userHistory && userHistory.length > 0) {
            return (
                <Flex className={'no-scrollbar'} direction={'column'} flex={'1 1 0'} mt={2} overflowY={'auto'}>
                    {userHistory.map((item: HistoryItem) => renderHistoryItem(item))}
                </Flex>
            )
        } else {
            return (
                <Flex flex={1} my={3} justifyContent={'center'} alignItems={'center'}>
                    <Text fontSize="sm" pt={4}>
                        {t('history.noHistory')}
                    </Text>
                </Flex>
            )
        }
    }

    return (
        <Flex direction={'column'} px={4} py={3} width={'100%'}>
            <Flex justifyContent={'space-between'}>
                <Text fontSize="lg" as="b">
                    {t('history.yourHistoryTitle')}
                </Text>
                {showCloseButton && (
                    <button onClick={onClose}>
                        <AiOutlineClose
                            style={{ height: 20, width: 20, color: Colors.text, cursor: 'pointer', opacity: 0.5 }}
                        />
                    </button>
                )}
            </Flex>
            {userHistory && renderHistory()}
        </Flex>
    )
}

export default History
