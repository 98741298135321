import { Chat } from '../../domain/models/Chat'
import { productParser } from './productParser'

export const chatParser = {
    toDomain: (apiData: any): Chat => {
        return {
            id: apiData.id,
            title: apiData.title,
            messages: apiData.messages,
            initialFlowFinished: true,
            product: productParser.toDomain(apiData.product),
        }
    },
}
