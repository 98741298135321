import { Dispatch } from 'redux'
import {
    LOAD_CHAT_ACTION,
    LoadChatAction,
    RESET_CHAT_STATE,
    SET_CHAT_ACTION,
    SET_POST_GENERATION_FLOW_STEP,
    SET_SAVE_CHAT_ON_MESSAGE_ACTION,
    SetChatAction,
    SetPostGenerationFlowStepAction,
    SetSaveChatOnMessageAction,
    SHOW_NEW_CHAT_DIALOG,
    ShowNewChatDialogAction,
} from './chatActions.redux'
import { chatRepository } from '../../../data/repositories/chatRepository'
import { Chat } from '../../models/Chat'

export type ChatActionsType = {
    setChat: (chat: Chat | null) => void
    loadChatFromHistory: (chatId: string) => Promise<void>
    setShowNewChatDialog: (show: boolean) => void
    setSaveChatOnNewMessage: (saveChatOnNewMessage: boolean) => void
    setPostGenerationFlowStep: (postGenerationFlow: number) => void
    resetChatState: () => void
}

export const chatActions = (dispatch: Dispatch): ChatActionsType => {
    const setChat: ChatActionsType['setChat'] = (chat) => {
        dispatch<SetChatAction>({
            type: SET_CHAT_ACTION,
            payload: {
                chat,
            },
        })
    }

    const loadChatFromHistory: ChatActionsType['loadChatFromHistory'] = async (chatId) => {
        try {
            resetChatState()

            const chat = await chatRepository().getChat(chatId)
            dispatch<LoadChatAction>({
                type: LOAD_CHAT_ACTION,
                payload: {
                    chat,
                },
            })
        } catch (e: any) {
            console.error(e)
            // TODO: Show error message
        }
    }

    const setShowNewChatDialog: ChatActionsType['setShowNewChatDialog'] = (show) => {
        dispatch<ShowNewChatDialogAction>({
            type: SHOW_NEW_CHAT_DIALOG,
            payload: {
                showNewChatDialog: show,
            },
        })
    }

    const setSaveChatOnNewMessage: ChatActionsType['setSaveChatOnNewMessage'] = (saveChatOnNewMessage) => {
        dispatch<SetSaveChatOnMessageAction>({
            type: SET_SAVE_CHAT_ON_MESSAGE_ACTION,
            payload: {
                saveChatOnNewMessage,
            },
        })
    }

    const setPostGenerationFlowStep: ChatActionsType['setPostGenerationFlowStep'] = (postGenerationFlowStep) => {
        dispatch<SetPostGenerationFlowStepAction>({
            type: SET_POST_GENERATION_FLOW_STEP,
            payload: {
                postGenerationFlowStep,
            },
        })
    }

    const resetChatState: ChatActionsType['resetChatState'] = () => {
        dispatch({
            type: RESET_CHAT_STATE,
        })
    }

    return {
        setChat,
        loadChatFromHistory,
        setShowNewChatDialog,
        setSaveChatOnNewMessage,
        setPostGenerationFlowStep,
        resetChatState,
    }
}
