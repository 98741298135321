import React, { FunctionComponent, useRef, useState } from 'react'
import { Message } from '../../domain/models/messages/Message'
import { Box, Flex, Input, Link, Text, Wrap } from '@chakra-ui/react'
import { MessageResponseTypes } from '../../domain/models/responses/MessageResponse'
import { MessageSelect } from '../../domain/models/messages/MessageSelect'
import { MessageResponseOption } from '../../domain/models/responses/MessageResponseSelect'
import { AiOutlineMessage, AiOutlineSend } from 'react-icons/ai'
import Colors from '../theme/Colors'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { StateType } from '../../domain/state/types'
import { PRODUCT_ID, subscriptionActions } from '../../domain/actions/subscription/subscriptionActions'
import { stripeRepository } from '../../data/repositories/stripeRepository'
import mixpanel from 'mixpanel-browser'
import { PaymentTrackEvents } from '../../utils/trackEvents'

interface Props {
    messages: Message[]
    onSendMessageClicked: (value: string) => void
    onResponseSelected: (selectedOption: MessageResponseOption) => void
}

const ActionSection: FunctionComponent<Props> = ({ messages, onSendMessageClicked, onResponseSelected }) => {
    const dispatch = useDispatch()
    const store = useStore()

    const { t } = useTranslation()
    const inputRef = useRef<any>()

    const [showSendButton, setShowSendButton] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>('')

    const { creditsAvailable } = useSelector((state: StateType) => {
        return state.subscriptionReducer
    })

    const handleSendMessage = () => {
        onSendMessageClicked(inputValue)
        setInputValue('')
        setShowSendButton(false)
    }

    const handleInputResponse = (value: string) => {
        if (value.length > 0) {
            setShowSendButton(true)
        } else {
            setShowSendButton(false)
        }
        setInputValue(value)
    }

    const handleUpgradeButtonClicked = async () => {
        mixpanel.track(PaymentTrackEvents.NO_CREDITS_MESSAGE_CLICKED)
        const checkoutUrl = await subscriptionActions(dispatch).getCheckoutSession(PRODUCT_ID, store)
        window.location.replace(checkoutUrl)
    }

    const renderInputSection = (message: Message) => {
        return (
            <form
                style={{ width: '100%' }}
                onSubmit={(e) => {
                    e.preventDefault()
                    handleSendMessage()
                }}>
                <Flex
                    backgroundColor={Colors.white}
                    mt={4}
                    w={'100%'}
                    borderRadius={12}
                    p={4}
                    borderColor={Colors.primary['500']}
                    borderWidth={1}
                    style={{ cursor: 'text' }}
                    onClick={() => {
                        inputRef.current?.focus()
                    }}>
                    <AiOutlineMessage style={{ height: 24, width: 24, color: Colors.primary['600'] }} />
                    <Input
                        ref={inputRef}
                        autoFocus
                        variant="unstyled"
                        placeholder={message.response?.placeholder || 'Escribe lo que quieras...'}
                        ml={2}
                        mr={4}
                        value={inputValue}
                        onChange={(e) => handleInputResponse(e.currentTarget.value)}
                    />
                    {showSendButton && (
                        <button type="submit">
                            <AiOutlineSend
                                style={{ height: 24, width: 24, color: Colors.primary['600'], cursor: 'pointer' }}
                            />
                        </button>
                    )}
                </Flex>
            </form>
        )
    }

    const renderSelectOption = (item: MessageResponseOption) => {
        return (
            <Box
                p={2}
                px={4}
                ml={2}
                maxW={'90%'}
                backgroundColor={Colors.whiteOpacity}
                cursor={'pointer'}
                borderRadius={12}
                borderColor={Colors.primary['900']}
                borderWidth={1}
                onClick={() => onResponseSelected(item)}>
                <Text fontSize="lg" color={Colors.primary['900']}>
                    {item.label}
                </Text>
            </Box>
        )
    }

    const renderSelectSection = (message: MessageSelect) => {
        return (
            <Flex direction={'column'} w={'100%'} alignItems={'flex-end'}>
                <Text mb={2} mr={1} as={'b'}>
                    {t('chat.chooseOptionsTitle')}
                </Text>
                <Wrap w={'100%'} justify={'flex-end'}>
                    {message.response?.options.map((option: MessageResponseOption) => {
                        return renderSelectOption(option)
                    })}
                </Wrap>
            </Flex>
        )
    }

    const renderActionSection = () => {
        const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null
        if (lastMessage == null) {
            return
        }

        if (lastMessage?.response && !lastMessage?.isMine) {
            const hasEnoughCredits = subscriptionActions(dispatch).getIfHasEnoughCredits(store)
            if (!creditsAvailable || !hasEnoughCredits) {
                return (
                    <Box w={'80%'} m={'0 auto'} textAlign={'center'}>
                        <Text fontSize={['md', 'lg']} color={Colors.text}>
                            {t('subscription.noCreditsAvailable')}
                            <Link color={Colors.primary['900']} onClick={() => handleUpgradeButtonClicked()}>
                                {t('subscription.clickHere')}
                            </Link>
                        </Text>
                    </Box>
                )
            } else {
                switch (lastMessage?.response?.type) {
                    case MessageResponseTypes.INPUT:
                        return renderInputSection(lastMessage)
                    case MessageResponseTypes.TITLE:
                        return renderInputSection(lastMessage)
                    case MessageResponseTypes.SELECT:
                        return renderSelectSection(lastMessage as MessageSelect)
                }
            }
        }

        return null
    }

    return (
        <Box h={'80px'} mt={2} display={'flex'} width={'100%'} alignItems={'flex-end'}>
            {renderActionSection()}
        </Box>
    )
}

export default ActionSection
