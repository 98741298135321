// ACTION TYPES
import { ProductListItem } from '../../models/products/ProductListItem'
import { Product } from '../../models/products/Product'

export const SET_PRODUCTS_LIST_ACTION = 'SET_PRODUCTS_LIST_ACTION'
export const SET_PRODUCT_ACTION = 'SET_PRODUCT_ACTION'
export const CLEAN_PRODUCTS_LIST_ACTION = 'CLEAN_PRODUCTS_LIST_ACTION'
export const CLEAN_PRODUCT_ACTION = 'CLEAN_PRODUCT_ACTION'
export const RESET_PRODUCT_STATE_ACTION = 'RESET_PRODUCT_STATE_ACTION'
export const SET_PRODUCT_ERROR_ACTION = 'SET_PRODUCT_ERROR_ACTION'

// ACTION CREATORS
export interface SetProductsListAction {
    type: typeof SET_PRODUCTS_LIST_ACTION
    payload: {
        productList: ProductListItem[]
    }
}

export interface SetProductAction {
    type: typeof SET_PRODUCT_ACTION
    payload: {
        product: Product
    }
}

export interface CleanProductsListAction {
    type: typeof CLEAN_PRODUCTS_LIST_ACTION
}

export interface CleanProductAction {
    type: typeof CLEAN_PRODUCT_ACTION
}

export interface ResetProductStateAction {
    type: typeof RESET_PRODUCT_STATE_ACTION
}

export interface SetProductErrorAction {
    type: typeof SET_PRODUCT_ERROR_ACTION
    payload: {
        error: string
    }
}

export type ProductActionReduxTypes =
    | SetProductsListAction
    | SetProductAction
    | CleanProductsListAction
    | CleanProductAction
    | ResetProductStateAction
    | SetProductErrorAction
