import { createClient } from '@supabase/supabase-js'

// Create a single supabase client for interacting with your database
export const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL || '',
    process.env.REACT_APP_SUPABASE_KEY || '',
    {
        auth: {
            storage: localStorage,
            autoRefreshToken: true,
            persistSession: true,
            detectSessionInUrl: true,
        },
    },
)

export const supabaseLogout = (history: any) => {
    // localStorage.removeItem(SESSION_CONTEXT_KEY)
    supabase.auth.signOut().then(() => {
        history.push('/login')
    })
}

export const getSupabaseSession = async (history?: any) => {
    const supabaseSession = await supabase.auth.getSession()
    if (supabaseSession?.data?.session) return supabaseSession.data.session

    if (history) {
        history.push('/login')
    }

    // const asyncStorageSession = await localStorage.getItem(SESSION_CONTEXT_KEY)
    // if (asyncStorageSession) {
    //     const parsedStorageSession = JSON.parse(asyncStorageSession)
    //     await supabase.auth.setSession(parsedStorageSession)
    //     return parsedStorageSession
    // } else {
    //     if (history) {
    //         history.push('/login')
    //     }
    // }
}
