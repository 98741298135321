import { initialState } from '../initialState'
import {
    SubscriptionActionReduxTypes,
    UPDATE_SUBSCRIPTION_DATA_ACTION,
} from '../../actions/subscription/subscriptionActions.redux'
import { Subscription } from '../../models/Subscription'
import { calculateRemainingCredits } from '../../actions/subscription/subscriptionActions'

export interface SubscriptionReducerType {
    subscriptionData: Subscription | null
    creditsAvailable: boolean
    remainingCredits: number
}

export const subscriptionReducer = (
    state: SubscriptionReducerType = initialState.subscriptionReducer,
    action: SubscriptionActionReduxTypes,
): SubscriptionReducerType => {
    switch (action.type) {
        case UPDATE_SUBSCRIPTION_DATA_ACTION:
            return {
                ...state,
                subscriptionData: action.payload.subscription,
                creditsAvailable: calculateRemainingCredits(
                    action.payload.subscription.plan,
                    action.payload.subscription.usedCredits,
                ) > 0,
                remainingCredits: calculateRemainingCredits(action.payload.subscription.plan, action.payload.subscription.usedCredits),
            }
        default:
            return { ...state }
    }
}
