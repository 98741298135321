import React from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import ChatScreen from './presentation/screens/chat'
import './App.css'
import './data/services/supabaseService'
import AuthScreen from './presentation/screens/auth'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import BrandTheme from './presentation/theme/BrandTheme'
import configureStore from './domain/store'
import { initialState } from './domain/state/initialState'
import { Provider } from 'react-redux'
import './services/localization/i18n'
import SuccessScreen from './presentation/screens/success/index'
import RecoverPasswordScreen from './presentation/screens/recover'

const store = configureStore(initialState)
const theme = extendTheme(BrandTheme)

export const App = () => (
    <Provider store={store}>
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route path={`/login`} component={AuthScreen} />
                    <Route path={`/recover-password`} component={RecoverPasswordScreen} />
                    <Route path={`/success`} component={SuccessScreen} />
                    <Route path={`/`} component={ChatScreen} />
                </Switch>
            </BrowserRouter>
        </ChakraProvider>
    </Provider>
)
