import React, { FunctionComponent, Ref } from 'react'
import { Message } from '../../domain/models/messages/Message'
import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import MessageCard from './MessageCard'
import Colors from '../theme/Colors'

interface Props {
    messages: Message[]
    isLoadingMessages: boolean
    lastMessageRef: Ref<any>
}

const ChatSection: FunctionComponent<Props> = ({ messages, isLoadingMessages, lastMessageRef }) => {
    const renderChatSection = () => {
        if (!messages || messages.length === 0) {
            return (
                <Flex flex={1} w={'100%'} justifyContent={'center'}>
                    <Spinner color={Colors.primary['600']} size="lg" />
                </Flex>
            )
        } else {
            return (
                <Flex
                    className={'chat-container no-scrollbar'}
                    flex={1}
                    flexBasis={0}
                    w={'100%'}
                    direction={'column'}
                    overflowY={'auto'}
                    pb={4}>
                    {messages.map((item: Message, index: number) => (
                        <MessageCard key={`message-${index}`} message={item} />
                    ))}
                    {isLoadingMessages && (
                        <Flex justifyContent={'center'} mb={4}>
                            <Spinner alignSelf={'center'} mt={4} color={Colors.primary['600']} size="lg" />
                        </Flex>
                    )}
                    <Box ref={lastMessageRef} />
                </Flex>
            )
        }
    }

    return renderChatSection()
}

export default ChatSection
