import * as React from 'react'
import { useEffect } from 'react'
import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react'
import Colors from '../../theme/Colors'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { use100vh } from 'react-div-100vh'
import mixpanel from 'mixpanel-browser'
import { PaymentTrackEvents } from '../../../utils/trackEvents'

const SuccessScreen = () => {
    const { t } = useTranslation()
    const height = use100vh()
    const history = useHistory()

    useEffect(() => {
        mixpanel.track(PaymentTrackEvents.SUCCESS_PAGE_VISIT)
        setTimeout(() => {
            history.push('/')
        }, 8000)
    }, [])

    return (
        <Flex h={height || '100vh'}>
            <Box
                flex={1}
                bgGradient={`linear(to-br, ${Colors.backgroundGradient.left} 0%, ${Colors.backgroundGradient.right} 100%)`}
                p={2}
                overflowY={'hidden'}>
                <Flex
                    h={'100%'}
                    maxW={{ xl: '1200px' }}
                    m="0 auto"
                    p={2}
                    maxH={'100%'}
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <Box maxW={'600px'} mx={'24px'}>
                        <Text fontSize={'2xl'} fontWeight={'bold'} textAlign={'center'} color={Colors.text}>
                            {t('successPayment.title')}
                        </Text>
                        <Text fontSize={'lg'} textAlign={'center'} mt={2} color={Colors.textSecondary}>
                            {t('successPayment.description')}
                        </Text>
                        <Center h="100%">
                            <Spinner color={Colors.primary['600']} size="lg" />
                        </Center>
                    </Box>
                </Flex>
            </Box>
        </Flex>
    )
}

export default SuccessScreen
