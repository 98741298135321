export enum MessageResponseTypes {
    INPUT = 'input',
    SELECT = 'select',
    TITLE = 'title',
}

export interface MessageResponse {
    key: string
    type: MessageResponseTypes
    placeholder: string
    required: boolean
}
