import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { subscriptionReducer } from './state/reducers/subscriptionReducer'
import { StateType } from './state/types'
import { historyReducer } from './state/reducers/historyReducer'
import { chatReducer } from './state/reducers/chatReducer'
import { categoriesReducer } from './state/reducers/categoriesReducer'
import { productReducer } from './state/reducers/productReducer'

const reducers = {
    subscriptionReducer,
    historyReducer,
    chatReducer,
    categoriesReducer,
    productReducer,
}

export default function configureStore(initialState: StateType) {
    const middlewares = [thunkMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer]
    const composedEnhancers = compose(...enhancers)
    const combinedReducers = combineReducers({
        ...reducers,
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const store = createStore(combinedReducers, initialState, composedEnhancers)

    return store
}
