import React, { FunctionComponent, useEffect, useState } from 'react'
import { Category } from '../../domain/models/Category'
import {
    Box,
    Center,
    Flex,
    IconButton,
    SimpleGrid,
    Spinner,
    Text,
    Tooltip,
    Image,
    useToast,
    HStack,
} from '@chakra-ui/react'
import Colors from '../theme/Colors'
import { getUserLanguage } from '../../utils/fucntions'
import i18n from 'i18next'
import { productActions } from '../../domain/actions/product/productActions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { ProductListItem } from '../../domain/models/products/ProductListItem'
import { StateType } from '../../domain/state/types'
import mixpanel from 'mixpanel-browser'
import { CategoriesTrackEvents } from '../../utils/trackEvents'
import CoinIcon from '../../assets/images/coin.png'

interface Props {
    categories: Category[]
    onProductSelected: () => void
}

const CategoriesSection: FunctionComponent<Props> = ({ categories, onProductSelected }) => {
    const dispatch = useDispatch()
    const toast = useToast()
    const { t } = useTranslation()

    const [categorySelected, setCategorySelected] = useState<Category | null>(null)
    const [filteredCategories, setFilteredCategories] = useState<Category[]>([])

    const { remainingCredits } = useSelector((state: StateType) => {
        return state.subscriptionReducer
    })

    const {
        productList,
        selectedProduct,
        error: productError,
    } = useSelector((state: StateType) => {
        return state.productReducer
    })

    useEffect(() => {
        if (categorySelected) {
            setFilteredCategories(categories.filter((category) => category.id === categorySelected.id))
            productActions(dispatch).getProductsByCategory(categorySelected.id, getUserLanguage(i18n.language))
        }
    }, [categorySelected])

    useEffect(() => {
        if (!categorySelected) {
            setFilteredCategories(categories)
        }
    }, [categories])

    useEffect(() => {
        if (categorySelected && selectedProduct) {
            onProductSelected()
        }
    }, [selectedProduct])

    useEffect(() => {
        if (productError) {
            toast({
                title: t(`${productError}.title`),
                description: t(`${productError}.description`),
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [productError])

    const unselectCategory = (event: any) => {
        event.stopPropagation()
        mixpanel.track(CategoriesTrackEvents.CATEGORY_SELECTED, {
            category_id: categorySelected?.id,
            category_title: categorySelected?.title,
        })
        setCategorySelected(null)
        setFilteredCategories(categories)
        productActions(dispatch).cleanProductList()
    }

    const handleProductSelected = (product: ProductListItem) => {
        mixpanel.track(CategoriesTrackEvents.PRODUCT_SELECTED, {
            prompt_id: product.promptId,
            product_id: product.id,
            product_title: product.title,
        })
        productActions(dispatch).getProductById(product.promptId, getUserLanguage(i18n.language))
    }

    const renderCategory = (category: Category) => {
        return (
            <Box
                backgroundColor={categorySelected ? Colors.white : Colors.whiteOpacity}
                borderRadius={12}
                p={4}
                cursor={'pointer'}
                transition={'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform'}
                transitionDuration={'0.2s'}
                border={categorySelected ? `1px solid ${Colors.primary['300']}` : `1px solid #FFFFFF00`}
                _hover={{
                    backgroundColor: Colors.white,
                    border: `1px solid ${Colors.primary['300']}`,
                }}
                onClick={() => {
                    mixpanel.track(CategoriesTrackEvents.CATEGORY_SELECTED, {
                        category_id: category.id,
                        category_title: category.title,
                    })
                    setCategorySelected(category)
                }}>
                <Flex justifyContent={'space-between'}>
                    <Center h={'44px'} w={'44px'} borderRadius={'22px'} backgroundColor={Colors.iconBackground}>
                        <Text fontSize={'2xl'}>{category.icon}</Text>
                    </Center>
                    {categorySelected && (
                        <Tooltip label={t('category.unselectCategory')} openDelay={500}>
                            <IconButton
                                color={Colors.text}
                                colorScheme="ctas"
                                aria-label={t('category.unselectCategory')}
                                icon={<AiOutlineCloseCircle />}
                                size={'md'}
                                onClick={(event) => unselectCategory(event)}
                            />
                        </Tooltip>
                    )}
                </Flex>
                <Text fontSize={'lg'} fontWeight={'bold'} mt={2} color={Colors.text}>
                    {category.title}
                </Text>
                <Text fontSize={'md'} color={Colors.textSecondary}>
                    {category.description}
                </Text>
            </Box>
        )
    }

    const renderProduct = (product: ProductListItem) => {
        const isDisabled = remainingCredits < product.creditCost
        return (
            <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                backgroundColor={Colors.whiteOpacity}
                borderRadius={12}
                p={4}
                cursor={!isDisabled ? 'pointer' : 'default'}
                transition={
                    !isDisabled
                        ? 'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform'
                        : undefined
                }
                transitionDuration={!isDisabled ? '0.2s' : undefined}
                border={`1px solid #FFFFFF00`}
                opacity={!isDisabled ? 1 : 0.6}
                _hover={
                    !isDisabled
                        ? {
                              backgroundColor: Colors.white,
                              border: `1px solid ${Colors.primary['300']}`,
                          }
                        : undefined
                }
                onClick={() => {
                    if (!isDisabled) handleProductSelected(product)
                }}>
                <Text fontSize={'md'} fontWeight={'bold'}>
                    {product.title}
                </Text>
                {product.creditCost > 1 && (
                    <Tooltip label={t('category.creditCost')} openDelay={500}>
                        <HStack spacing={1}>
                            <Text fontSize={'md'} fontWeight={'bold'} color={Colors.textSecondary}>
                                {product.creditCost}
                            </Text>
                            <Image src={CoinIcon} boxSize={'18px'} />
                        </HStack>
                    </Tooltip>
                )}
            </Flex>
        )
    }

    const renderProductsSection = () => {
        return (
            <Box>
                <Flex className={'dividerWithText'} w={'100%'} alignItems={'center'} textAlign={'center'} my={4}>
                    <Box maxW={'90%'} px={5} mb={1}>
                        <Text fontSize="sm" as={'b'}>
                            {t('category.selectProductTitle')}
                        </Text>
                    </Box>
                </Flex>
                {productList.length > 0 ? (
                    <SimpleGrid columns={[1, 2, 3]} spacing={[4, 6]}>
                        {productList.map((product: ProductListItem) => renderProduct(product))}
                    </SimpleGrid>
                ) : (
                    <Center h="100%">
                        <Spinner color={Colors.primary['600']} size="lg" />
                    </Center>
                )}
            </Box>
        )
    }

    const renderCategoriesSection = () => {
        if (categories.length > 0) {
            return (
                <Flex
                    className={'no-scrollbar'}
                    flex={1}
                    flexBasis={0}
                    w={'100%'}
                    direction={'column'}
                    overflowY={'auto'}>
                    <SimpleGrid columns={[1, 2, 3]} spacing={[4, 6]}>
                        {filteredCategories.map((category) => renderCategory(category))}
                    </SimpleGrid>
                    {categorySelected && renderProductsSection()}
                </Flex>
            )
        } else {
            return (
                <Center h="100%">
                    <Spinner color={Colors.primary['600']} size="lg" />
                </Center>
            )
        }
    }

    return renderCategoriesSection()
}

export default CategoriesSection
