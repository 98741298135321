import React, { FunctionComponent, useState } from 'react'
import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, Show } from '@chakra-ui/react'
import Header from '../components/header/Header'
import History from '../components/history/History'
import { use100vh } from 'react-div-100vh'
import Colors from '../theme/Colors'

interface Props {
    showHeader?: boolean
    children: JSX.Element
}

export const MainLayout: FunctionComponent<Props> = ({ showHeader = true, children }) => {
    const height = use100vh()
    const [showHistory, setShowHistory] = useState(false)

    return (
        <Flex h={height || '100vh'}>
            <Box
                flex={1}
                bgGradient={`linear(to-br, ${Colors.backgroundGradient.left} 0%, ${Colors.backgroundGradient.right} 100%)`}
                p={[2, 4, 8]}
                overflowY={'hidden'}>
                <Flex direction="column" h={'100%'} maxW={{ xl: '1200px' }} m="0 auto" p={4}>
                    {showHeader && <Header onShowHistory={() => setShowHistory(!showHistory)} />}
                    <Flex direction="row" h={'100%'}>
                        {showHistory && (
                            <Show above="md">
                                <Flex
                                    flex={1}
                                    backgroundColor={Colors.whiteOpacity}
                                    borderRadius={12}
                                    mr={6}
                                    overflowY={'hidden'}>
                                    <History />
                                </Flex>
                            </Show>
                        )}
                        <Show below="md">
                            <Drawer placement={'left'} onClose={() => setShowHistory(false)} isOpen={showHistory}>
                                <DrawerOverlay />
                                <DrawerContent>
                                    <Flex
                                        flex={1}
                                        backgroundColor={Colors.whiteOpacity}
                                        borderRadius={12}
                                        overflowY={'hidden'}>
                                        <History showCloseButton onClose={() => setShowHistory(false)} />
                                    </Flex>
                                    {/*<DrawerHeader borderBottomWidth="1px">Basic Drawer</DrawerHeader>*/}
                                    {/*<DrawerBody>*/}
                                    {/*    <p>Some contents...</p>*/}
                                    {/*    <p>Some contents...</p>*/}
                                    {/*    <p>Some contents...</p>*/}
                                    {/*</DrawerBody>*/}
                                </DrawerContent>
                            </Drawer>
                        </Show>
                        <Flex flex={2}>{children}</Flex>
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    )
}
