import { Dispatch } from 'redux'
import { productRepository } from '../../../data/repositories/productRepository'
import {
    CLEAN_PRODUCT_ACTION,
    CLEAN_PRODUCTS_LIST_ACTION,
    SET_PRODUCT_ACTION,
    SET_PRODUCT_ERROR_ACTION,
    SET_PRODUCTS_LIST_ACTION,
    SetProductErrorAction,
} from './productActions.redux'

export type ProductActionsType = {
    getProductsByCategory: (categoryId: number, language: string) => void
    getProductById: (productId: string, language: string) => void
    cleanProductList: () => void
    cleanProduct: () => void
    resetProductState: () => void
}

export const productActions = (dispatch: Dispatch): ProductActionsType => {
    const getProductsByCategory: ProductActionsType['getProductsByCategory'] = async (categoryId, language) => {
        dispatch<SetProductErrorAction>({
            type: SET_PRODUCT_ERROR_ACTION,
            payload: {
                error: '',
            },
        })

        try {
            const productList = await productRepository().getProductListByCategory(categoryId, language)
            dispatch({
                type: SET_PRODUCTS_LIST_ACTION,
                payload: {
                    productList,
                },
            })
        } catch (error: any) {
            console.error(error)
            dispatch<SetProductErrorAction>({
                type: SET_PRODUCT_ERROR_ACTION,
                payload: {
                    error: 'error.productListNotLoaded',
                },
            })
        }
    }

    const getProductById: ProductActionsType['getProductById'] = async (promptId, language) => {
        dispatch<SetProductErrorAction>({
            type: SET_PRODUCT_ERROR_ACTION,
            payload: {
                error: '',
            },
        })

        try {
            const product = await productRepository().getProductById(promptId, language)
            dispatch({
                type: SET_PRODUCT_ACTION,
                payload: {
                    product,
                },
            })
        } catch (error: any) {
            console.error(error)
            dispatch<SetProductErrorAction>({
                type: SET_PRODUCT_ERROR_ACTION,
                payload: {
                    error: 'error.productNotLoaded',
                },
            })
        }
    }

    const cleanProductList: ProductActionsType['cleanProductList'] = () => {
        dispatch({
            type: CLEAN_PRODUCTS_LIST_ACTION,
        })
    }

    const cleanProduct: ProductActionsType['cleanProduct'] = () => {
        dispatch({
            type: CLEAN_PRODUCT_ACTION,
        })
    }

    const resetProductState: ProductActionsType['resetProductState'] = () => {
        dispatch({
            type: CLEAN_PRODUCT_ACTION,
        })
    }

    return {
        getProductsByCategory,
        getProductById,
        cleanProductList,
        cleanProduct,
        resetProductState,
    }
}
