import { initialState } from '../initialState'
import { Category } from '../../models/Category'
import {
    CategoriesActionReduxTypes,
    GET_CATEGORIES_ACTION,
    SET_CATEGORIES_ERROR_ACTION,
} from '../../actions/categories/categoriesActions.redux'

export interface CategoriesReducerType {
    categories: Category[]
    error: string
}

export const categoriesReducer = (
    state: CategoriesReducerType = initialState.categoriesReducer,
    action: CategoriesActionReduxTypes,
): CategoriesReducerType => {
    switch (action.type) {
        case GET_CATEGORIES_ACTION:
            return {
                ...state,
                categories: action.payload.categories,
            }
        case SET_CATEGORIES_ERROR_ACTION:
            return {
                ...state,
                error: action.payload.error,
            }
        default:
            return { ...state }
    }
}
