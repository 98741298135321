// ACTION TYPES
import { Subscription } from '../../models/Subscription'

export const UPDATE_SUBSCRIPTION_DATA_ACTION = 'UPDATE_SUBSCRIPTION_DATA_ACTION'

export interface UpdateSubscriptionDataAction {
    type: typeof UPDATE_SUBSCRIPTION_DATA_ACTION
    payload: {
        subscription: Subscription
    }
}

export type SubscriptionActionReduxTypes = UpdateSubscriptionDataAction
