// ACTION TYPES
import { HistoryItem } from '../../models/HistoryItem'

export const UPDATE_HISTORY_DATA_ACTION = 'UPDATE_HISTORY_DATA_ACTION'
export const SET_HISTORY_ERROR_ACTION = 'SET_HISTORY_ERROR_ACTION'

export interface UpdateHistoryDataAction {
    type: typeof UPDATE_HISTORY_DATA_ACTION
    payload: {
        userHistory: HistoryItem[]
    }
}

export interface SetHistoryErrorAction {
    type: typeof SET_HISTORY_ERROR_ACTION
    payload: {
        error: string
    }
}

export type HistoryActionReduxTypes = UpdateHistoryDataAction | SetHistoryErrorAction
