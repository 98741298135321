import { MessageResponse } from '../responses/MessageResponse'

export enum MessageTypes {
    MESSAGE = 'message',
    QUESTION = 'question',
    SUBMIT = 'submit',
    DIVIDER = 'divider',
    MESSAGE_HIGHLIGHT = 'highlight',
}

export interface Message {
    message: string
    response: MessageResponse | null
    type: MessageTypes
    isMine?: boolean
    // TODO Change is Mine by user type (user, initialFlow, assistant)
}
