import { Message, MessageTypes } from '../domain/models/messages/Message'

export const generatePromptInputsFromMessages = (messages: Message[]): object => {
    const promptInput: { [key: string]: string } = {}

    let nextMessageIsAnAnswer = false
    let questionKey: string | null = null
    messages.forEach((message) => {
        if (nextMessageIsAnAnswer && questionKey) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (message?.response?.selectedOption) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                promptInput[questionKey] = message.response.selectedOption
            } else {
                promptInput[questionKey] = message.message
            }

            nextMessageIsAnAnswer = false
            questionKey = null
        }

        if (message.type === MessageTypes.QUESTION && message.response) {
            nextMessageIsAnAnswer = true
            questionKey = message.response.key
        }
    })

    return promptInput
}
