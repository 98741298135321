import { Dispatch } from 'redux'
import {
    SET_HISTORY_ERROR_ACTION,
    SetHistoryErrorAction,
    UPDATE_HISTORY_DATA_ACTION,
    UpdateHistoryDataAction,
} from './historyActions.redux'
import { chatRepository } from '../../../data/repositories/chatRepository'

export type HistoryActionsType = {
    getUserHistory: () => void
}

export const historyActions = (dispatch: Dispatch): HistoryActionsType => {
    const getUserHistory: HistoryActionsType['getUserHistory'] = async () => {
        dispatch<SetHistoryErrorAction>({
            type: SET_HISTORY_ERROR_ACTION,
            payload: {
                error: '',
            },
        })

        try {
            const userHistory = await chatRepository().getChats()

            dispatch<UpdateHistoryDataAction>({
                type: UPDATE_HISTORY_DATA_ACTION,
                payload: {
                    userHistory,
                },
            })
        } catch (e: any) {
            console.error(e)
            dispatch<SetHistoryErrorAction>({
                type: SET_HISTORY_ERROR_ACTION,
                payload: {
                    error: e.message,
                },
            })
        }
    }

    return {
        getUserHistory,
    }
}
