export const Colors = {
    primary: {
        100: '#d7fcf3',
        300: '#a6eae0',
        500: '#4fd0ce',
        600: '#4bbcbd',
        900: '#3d848f',
    },
    backgroundGradient: {
        left: '#effff9',
        right: '#c0f1e7',
    },
    white: '#FFFFFF',
    whiteOpacity: 'rgba(255,255,255,0.8)',
    divider: 'rgba(0,0,0,0.1)',
    text: '#334155',
    textSecondary: '#64748B',
    iconBackground: '#F1F5F9BF',
}

export default Colors
