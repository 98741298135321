import React, { FunctionComponent } from 'react'
import { Avatar, Box, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Show, Tooltip } from '@chakra-ui/react'
import Colors from '../../theme/Colors'
import { useHistory } from 'react-router-dom'
import { supabaseLogout } from '../../../data/services/supabaseService'
import QuotaBar from '../QuotaBar'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { StateType } from '../../../domain/state/types'
import { AiOutlineHistory, AiOutlinePlusCircle } from 'react-icons/ai'
import { chatActions } from '../../../domain/actions/chat/chatActions'
import { stripeRepository } from '../../../data/repositories/stripeRepository'
import {
    PRODUCT_ID,
    getPlanCredits,
    subscriptionActions,
} from '../../../domain/actions/subscription/subscriptionActions'
import mixpanel from 'mixpanel-browser'
import { PaymentTrackEvents } from '../../../utils/trackEvents'
import i18n from 'i18next'

interface Props {
    onShowHistory: () => void
}

const Header: FunctionComponent<Props> = ({ onShowHistory }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const store = useStore()

    const subscriptionData = useSelector((state: StateType) => {
        return state.subscriptionReducer.subscriptionData
    })

    const handleCheckoutClicked = async () => {
        mixpanel.track(PaymentTrackEvents.HEADER_UPGRADE_BUTTON_CLICKED)
        const checkoutUrl = await subscriptionActions(dispatch).getCheckoutSession(PRODUCT_ID, store)
        window.location.replace(checkoutUrl)
    }

    const handleSupportClicked = () => {
        const supportUrl = i18n.language.includes('es') ? 'https://tally.so/r/n9BPOY' : 'https://tally.so/r/nW2XPa'
        window.open(supportUrl, '_blank')
    }

    const handleBillingItemClicked = async () => {
        if (subscriptionData?.customerId) {
            mixpanel.track(PaymentTrackEvents.CUSTOMER_PORTAL_CLICKED)
            const customerPortalUrl = await stripeRepository().getCustomerPortalSession(subscriptionData.customerId)
            window.location.replace(customerPortalUrl)
        }
    }

    const showQuotaBar = () => {
        return subscriptionData?.plan === 0 || subscriptionData?.plan === 1
    }

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            mb={6}
            p={4}
            pl={[4, 4, 8]}
            backgroundColor={Colors.whiteOpacity}
            borderRadius={12}>
            <Flex align="center">
                <Show above={'md'}>
                    <img
                        alt="Speechie"
                        src="https://speechai-divolut.s3.eu-west-3.amazonaws.com/speechieai-logo.png"
                        style={{ maxWidth: 200, marginLeft: -10, marginRight: -10 }}
                    />
                </Show>
                <Tooltip label={t('header.showHistory')} openDelay={500}>
                    <IconButton
                        color={Colors.text}
                        colorScheme="ctas"
                        aria-label={t('header.showHistory')}
                        icon={<AiOutlineHistory />}
                        mx={1}
                        ml={[0, 0, 4]}
                        onClick={onShowHistory}
                    />
                </Tooltip>
                <Tooltip label={t('header.newChat')} openDelay={500}>
                    <IconButton
                        color={Colors.text}
                        colorScheme="ctas"
                        aria-label={t('header.newChat')}
                        icon={<AiOutlinePlusCircle />}
                        mx={1}
                        onClick={() => chatActions(dispatch).setShowNewChatDialog(true)}
                    />
                </Tooltip>
                <Show below={'md'}>
                    <img
                        alt="Speechie"
                        src="https://speechai-divolut.s3.eu-west-3.amazonaws.com/speechieai-logo.png"
                        style={{ maxWidth: 160 }}
                    />
                </Show>
            </Flex>

            <Box display={{ base: 'block' }} flexBasis={{ base: 'auto' }}>
                <Flex align={'center'} justify={'center'} direction={'row'} pt={0}>
                    {subscriptionData && showQuotaBar() && (
                        <Show above={'md'}>
                            <QuotaBar
                                max={getPlanCredits(subscriptionData.plan)}
                                current={Math.max(
                                    getPlanCredits(subscriptionData.plan) - subscriptionData.usedCredits,
                                    0,
                                )}
                            />
                        </Show>
                    )}
                    <Menu>
                        <MenuButton>
                            <Avatar
                                size="sm"
                                src="https://bit.ly/broken-link"
                                color={'gray.800'}
                                backgroundColor={Colors.primary['300']}
                            />
                        </MenuButton>
                        <MenuList>
                            {subscriptionData && showQuotaBar() && (
                                <Show below={'md'}>
                                    <MenuItem borderBottom={`1px solid ${Colors.divider}`} pb={3}>
                                        <QuotaBar
                                            max={getPlanCredits(subscriptionData.plan)}
                                            current={
                                                getPlanCredits(subscriptionData.plan) - subscriptionData.usedCredits
                                            }
                                        />
                                    </MenuItem>
                                </Show>
                            )}
                            {subscriptionData?.plan === 0 && (
                                <MenuItem onClick={() => handleCheckoutClicked()}>{t('header.upgradePlan')}</MenuItem>
                            )}
                            {subscriptionData?.customerId && (
                                <MenuItem onClick={() => handleBillingItemClicked()}>
                                    {t('header.menu.billing')}
                                </MenuItem>
                            )}
                            <MenuItem onClick={() => handleSupportClicked()}>{t('header.menu.support')}</MenuItem>
                            <MenuItem onClick={() => supabaseLogout(history)}>{t('header.menu.logout')}</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Box>
        </Flex>
    )
}

export default Header
