import { Dispatch } from 'redux'
import { categoryRepository } from '../../../data/repositories/categoryRepository'
import { GET_CATEGORIES_ACTION, SET_CATEGORIES_ERROR_ACTION, SetCategoriesErrorAction } from './categoriesActions.redux'

export type CategoriesActionsType = {
    getCategories: (language: string) => void
}

export const categoriesActions = (dispatch: Dispatch): CategoriesActionsType => {
    const getCategories: CategoriesActionsType['getCategories'] = async (language) => {
        dispatch<SetCategoriesErrorAction>({
            type: SET_CATEGORIES_ERROR_ACTION,
            payload: {
                error: '',
            },
        })

        try {
            const categories = await categoryRepository().getAllCategories(language)
            dispatch({
                type: GET_CATEGORIES_ACTION,
                payload: {
                    categories,
                },
            })
        } catch (error: any) {
            console.error(error)
            dispatch<SetCategoriesErrorAction>({
                type: SET_CATEGORIES_ERROR_ACTION,
                payload: {
                    error: 'error.categoryNotLoaded',
                },
            })
        }
    }

    return {
        getCategories,
    }
}
