import axios from 'axios'

export const JWT_KEY = 'accessToken'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000'
axios.defaults.timeout = 25000

// axios.interceptors.request.use(
//     async (config) => {
//         const URL = config.url
//         const accessToken = localStorage.getItem(JWT_KEY)
//
//         if (
//             !URL?.includes('/login') &&
//             !URL?.includes('/register') &&
//             !URL?.includes('/recoveryRequests/request') &&
//             !URL?.includes('/recoveryRequests/recover')
//         ) {
//             if (accessToken) {
//                 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//                 // @ts-ignore
//                 config.headers = {
//                     ...config.headers,
//                     authorization: `Bearer ${accessToken}`,
//                 }
//             } else {
//                 window.location.href = '/login'
//             }
//         }
//
//         return config
//     },
//     (error) => Promise.reject(error),
// )
//
// axios.interceptors.request.use(
//     async (config) => {
//         const accessToken = localStorage.getItem(JWT_KEY)
//
//         if (accessToken) {
//             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//             // @ts-ignore
//             config.headers = {
//                 ...config.headers,
//                 authorization: `Bearer ${accessToken}`,
//             }
//         }
//
//         return config
//     },
//     (error) => Promise.reject(error),
// )

export const axiosInstance = axios
